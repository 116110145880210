/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

/* @import "ng-zorro-antd/ng-zorro-antd.min.css";
@import "ng-zorro-antd/style/index.min.css";
@import "ng-zorro-antd/button/style/index.min.css"; */
html {
    font-size: 16px;
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
aside,
header,
label {
    margin: 0;
    padding: 0;
}

ul,
ol {
    list-style: none;
}

.theme-green .bs-datepicker-head {
    background-color: #0041f0 !important;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: #0041f0 !important;
}

body {
    background-color: #eee !important;
    font-size: 12px !important;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
}

.v-hide {
    visibility: hidden !important;
}

.v-show {
    visibility: visible !important;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.tab_box {
    width: 100%;
    overflow-x: scroll;
}

.load_box {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: rgb(112, 112, 112) !important;
    font-size: 13px !important;
    margin-bottom: 12px;
}

.popup-footer button.confirm {
    color: #fff;
}

.mat-mdc-table mat-header-cell {
    /* padding-right: 20px; */
}

.mat-mdc-table mat-cell {
    padding-left: 0 !important;
    display: inline-block !important;
    line-height: 48px;
    padding-right: 20px;
}

mat-cell:first-of-type,
mat-header-cell:first-of-type,
mat-footer-cell:first-of-type {
    display: flex !important;
}

.mat-mdc-table .mat-mdc-cell:not(.not-auto-ellipsis) {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    word-wrap: normal;
    /* width: 100%;  */
}

.flex-al-center {
    display: flex;
    align-items: center;
}

.mat-mdc-table .mat-mdc-header-cell {
    line-height: 56px;
    padding-left: 10px;
    margin-right: 10px;
    margin-left: -10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}

.mat-sort-header-content {
    text-align: center;
    /* display: flex; */
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block !important;
}

.mat-mdc-table .mat-sort-header-container {
    align-items: center;
    height: 100%;
    /* padding: 0 24px; */
}

.mat-mdc-table .mat-mdc-header-cell[mat-sort-header]:hover {
    background-color: #ffffff;
}

.mat-mdc-table .mat-mdc-header-cell .mat-sort-header-button {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
}

.mat-mdc-table .mat-mdc-header-cell .mat-sort-header-arrow {
    margin-right: auto !important;
}

.mat-mdc-table .mat-mdc-header-cell:hover .caret {
    background-color: #f5f5f5;
}

.mat-mdc-table .mat-mdc-header-row {
    align-items: normal;
}

.mat-mdc-header-row,
.mat-mdc-row {
    display: flex;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    align-items: center;
    padding: 0 24px !important;
    box-sizing: border-box;
}

.mat-mdc-dialog-container {
    padding: 0 !important;
    font-family: Arial, Graphik;
}

.filter-container {
    height: 56px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
}

.filter-container div:first-child {
    height: 56px;
}

.filter-container .caret {
    display: flex;
    align-items: center;
}

.filter-container .caret .material-icons {
    width: 20px;
    display: inline;
    font-size: 20px;
    line-height: 0px;
}

.material-icons {
    line-height: 1;
}

.tab-thead .tab-thead-td {
    padding: 10px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tab-thead .tab-thead-td:hover {
    background-color: #ffffff;
}

bs-datepicker-container,
bs-daterangepicker-container {
    z-index: 1080 !important;
}

.mat-mdc-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow {
    opacity: 1 !important;
    transform: translateY(0) !important;
}


/* customer material checkbox */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.cus-checkbox .mat-checkbox-checkmark-path {
    stroke: #0041f0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.cus-checkbox .mat-checkbox-background {
    border: 2px solid #555;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.cus-checkbox.no-border .mat-checkbox-background {
    border: 2px solid transparent;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.cus-checkbox.mat-checkbox-checked .mat-checkbox-background,
.cus-checkbox.mat-checkbox-indeterminate .mat-checkbox-background {
    background: #FFF !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.cus-checkbox .mat-checkbox-inner-container {
    width: 15px;
    height: 15px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.cus-checkbox .mat-checkbox-frame {
    border: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.cus-checkbox .mat-checkbox-ripple {
    display: none;
}

/* bug 136653 */
.mat-mdc-header-cell button:focus {
    outline: none;
}

.modal-dialog.termination .modal-content {
    border-radius: 0;
}

.tooltip.tooltip-class-country {
    max-width: 500px !important;
}